import { FormControl, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUpsStatusFilter } from 'src/reducers/slices/FilterSlice';
import { upsStatsEnum } from '../utilities/constant';

const SystemStatusFloatingFilter = (props) => {
  const upsStatusFilter = useSelector((state) => state.filters.upsStatusFilter);
  const dispatch = useDispatch();

  const onChange = (event) => {
    const selectedValue = event.target.value;
    const newFilter = {
      column_name: 'state',
      type: 'exact',
      value: selectedValue === 'DISCONNECTED' ? null : selectedValue,
    };
    dispatch(setUpsStatusFilter(newFilter));
    if (props?.context?.setFilters) {
      // Replace or add the new filter for upsStatus
      props.context.setFilters((prevFilters) => {
        prevFilters = prevFilters.filter((f) => f.column_name !== 'state');
        const updatedFilters = [...prevFilters, newFilter];
        return updatedFilters;
      });
    }
  };

  useEffect(() => {
    return () => dispatch(setUpsStatusFilter(null));
  }, [dispatch]);

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Select
        labelId="system-status-label"
        size="small"
        value={
          upsStatusFilter
            ? upsStatusFilter.value === null
              ? 'DISCONNECTED'
              : upsStatusFilter.value
            : ''
        }
        onChange={onChange}
        sx={{ height: '25px', backgroundColor: 'white' }}
      >
        <MenuItem value={''} disabled>
          SELECT STATUS
        </MenuItem>
        <MenuItem value={'DISCONNECTED'}>DISCONNECTED</MenuItem>
        {upsStatsEnum.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SystemStatusFloatingFilter;
