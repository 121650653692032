import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  upsStatusFilter: null,
  accessLevelFilter: null,
  servicePartnerFilter: null,
  batteryStatusFilter: '',
};

const setting = createSlice({
  name: 'FilterSlice',
  initialState,
  reducers: {
    setUpsStatusFilter: (state, action) => {
      state.upsStatusFilter = action.payload;
    },
    setAccessLevelFilter: (state, action) => {
      state.accessLevelFilter = action.payload;
    },
    setServicePartnersFilter: (state, action) => {
      state.servicePartnerFilter = action.payload;
    },
    setBatteryStatusFilter: (state, action) => {
      state.batteryStatusFilter = action.payload;
    },
  },
});

export const {
  setUpsStatusFilter,
  setAccessLevelFilter,
  setServicePartnersFilter,
  setBatteryStatusFilter,
} = setting.actions;

export default setting.reducer;
