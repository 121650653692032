import { FormControl, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListPartners } from 'src/ApiCalls/AuthorizedServicePartnerApiCalls';
import { setServicePartnersFilter } from 'src/reducers/slices/FilterSlice';

const ServicePartnerFloatingFilter = (props) => {
  const servicePartnerFilter = useSelector((state) => state.filters.servicePartnerFilter);

  const dispatch = useDispatch();

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    (async () => {
      let result = await ListPartners({
        page: 1,
        page_size: 1000,
      });
      const partnersNames = result?.data.map((partner) => partner.company_name); // Use the correct field
      setPartners(partnersNames ?? []);
    })();
  }, []);

  const onChange = (event) => {
    const selectedValue = event.target.value;

    const newFilter = {
      column_name: 'company_name',
      relation_key: 'service_partner',
      type: 'like',
      value: selectedValue,
    };

    dispatch(setServicePartnersFilter(newFilter));

    if (props?.context?.setFilters) {
      // Replace or add the new filter for upsStatus
      props.context.setFilters((prevFilters) => {
        prevFilters = prevFilters.filter((f) => f.column_name !== 'company_name');
        const updatedFilters = [...prevFilters, newFilter];
        return updatedFilters;
      });
    }
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Select
        labelId="service-partner-label"
        size="small"
        value={servicePartnerFilter ? servicePartnerFilter.value : ''}
        onChange={onChange}
        sx={{ height: '25px', backgroundColor: 'white' }}
      >
        <MenuItem value={''} disabled>
          SELECT SERVICE PARTNER
        </MenuItem>
        {partners.map((partner, index) => (
          <MenuItem key={index} value={partner}>
            {partner}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ServicePartnerFloatingFilter;
